<template>
  <v-col :cols="cols" :offset="offset" class="my-0 py-0">
    <v-checkbox class="p" density="default" v-model="data" :rules="rules" :label="getLabel"></v-checkbox>
  </v-col>
</template>

<script>
export default {
  name: "CheckboxField",
  props: {
    label:{},
    modelValue:{},
    rules:{},
    cols:{
      default:12,
    },
    offset:{},
    required:{
      type: Boolean,
      default: false,
    }
  },
  computed: {
    getLabel: {
      get: function() { return this.required?this.label+"*":this.label},
    },
    data: {
      get: function() { return this.modelValue},
      set: function(val) { this.$emit('update:modelValue',val)}
    }
  },
  methods: {
  }
}
</script>

<style >
  .v-selection-control__input {
    margin-left:-20px;
    padding-right:10px;
  }
  .v-label--clickable {
    margin-left:-10px;
  }
</style>