// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
        theme: {
            defaultTheme: 'myCustomTheme',
            themes: {
                myCustomTheme: {
                    dark: false,
                    colors: {
                        primary: '#9C2C13',
                        secondary: '#c3c4c7'
                    }
                }
            }
        },

    }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
