<template>
  <div class="text-left">
    <h3>Mitgliedschaftsbedingungen</h3>
    <div class="terms">
      <p>
        Der Mitgliedsbeitrag hat derzeit eine Höhe von 30 EUR pro Kalenderjahr und wird erstmalig ca. 4 Wochen nach Antragsstellung eingezogen. In den Folgejahren erfolgt der Einzug im November.

      </p>
      <p>
        Die Mitgliedschaft kann jährlich ohne Angabe von Gründen schriftlich gekündigt werden. Nutzen Sie hierzu gerne auch eine formlose E-Mail an <a :href="'mailto:'+email">{{email}}</a>.<br>
        Sofern die Kündigung nicht bis zum 31. Oktober eingegangen ist, verlängert sie sich automatisch um ein weiteres Kalenderjahr.<br>
        Sie haben das Recht, bis zu 14 Tage nach Abschluss einer Mitgliedschaft kostenfrei zurückzutreten.
      </p>
      <p>
        Eine Veränderung der Mitgliedschaftsgebühr bedarf der Genehmigung durch die Jahreshauptversammlung, an der alle Mitglieder stimmberechtigt sind.<br>
        Mit der Angabe einer E-Mail in diesem Antrag laden wir Sie automatisch zur Jahreshauptversammlung ein.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsConditions",
  props: ["email"]
}
</script>

<style scoped>
  .terms {
    font-size: medium;
    padding:10px;
    margin-left: 50px;
  }
  p {
    padding-bottom: 10px;
  }
</style>