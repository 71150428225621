
<script>
import iban from "@/mixins/iban";

export default {
  name: "FormValidationMixin",
  data:() => ({
  }),
  methods: {
    requiredRule:(text) => value => {
      if (value?.length>0) return true; return text
    },
    emailRule:(text) => value => {
      if (/.+@.+\..+/.test(value)) return true
      return text
    },
    requiredCheckbox:(text) => value => {
      if (value && value===true) return true; return text
    },
    ibanRule:(text) => value => {
        if (iban.Validate(value)===1) return true; return text
    },



  },
}
</script>

<style scoped>

</style>