
import axios from "axios";

const api = (
    {
        postApplication: (req) => axios.post(process.env.VUE_APP_API+'apply',[req]),
        confirmApplication: (req) => axios.post(process.env.VUE_APP_API+'confirm',[{id:req}]),
        report: (req) => axios.get(process.env.VUE_APP_API+'report?id='+req),
        checkIban: (text) => axios.get(process.env.VUE_APP_API+'iban-check/' + text)
    }
)

export default api