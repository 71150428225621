import { createWebHashHistory, createRouter } from "vue-router";
import Homepage from "@/views/Homepage";
import Antrag from "@/views/Antrag";
import Impressum from "@/views/Impressum";
import Datensicherheit from "@/views/Datensicherheit";
import Confirmation from "@/views/Confirmation";
import Report from "@/views/Report";
const routes = [
    {
        path: "/impressum",
        name: "impressum",
        component: Impressum,
    },
    {
        path: "/datensicherheit",
        name: "datensicherheit",
        component: Datensicherheit,
    },
    {
        path: "/mitglied",
        name: "Antrag",
        component: Antrag,
    },
    {
        path: "/confirm",
        name: "Confirmation",
        component: Confirmation,
    },
    {
        path: "/report",
        name: "Report",
        component: Report,
    },
    {
        path: "/",
        name: "home",
        component: Homepage,
    },

];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;