<template>
  <v-container>

      <v-row class="text-left">
        <v-col cols="6" >
            Herausgeber dieser Webseite ist der<br>
            <b>Förderkreis Basketball e.V.</b><br>
            Berliner Straße 32<br>
            35305 Grünberg<br>
            <a href="'mailto:'+email">{{email}}</a>
          vertreten durch den 1. Vorsitzenden, Eberhard Spissinger
        </v-col>
      </v-row>
    <v-row >
      <v-col >
        <h3>Angaben zur Webseite</h3>
      </v-col>
    </v-row>
      <v-row class="text-left" >
        <v-col >
          <p>Alle in den Internetseiten enthaltenen Angaben und Informationen wurden von uns oder Dritten sorgfältig recherchiert und geprüft.</p>
          <p>Für Richtigkeit, Vollständigkeit und Aktualität können weder der Förderkreis Basketball e.V. noch dritte Lieferanten die Haftung übernehmen.
            Alle Informationen dienen ausschließlich zur Information der Besucher dieses Onlineangebotes.
          </p>
          <p>
          Für Internetseiten Dritter, auf die durch Hyperlink verwiesen wird, tragen die jeweiligen Anbieter die Verantwortung.
            Der Förderkreis Basketball e.V. ist für den Inhalt solcher Seiten Dritter nicht verantwortlich.
            Des Weiteren kann die Web-Seite des Förderkreis Basketball e.V. ohne deren Wissen von einer anderen Web-Seite mittels Hyperlink angelinkt worden sein.
            Der Förderkreis Basketball e.V. übernimmt keine Verantwortung für Darstellungen, Inhalt oder irgendeine Verbindung zu Web-Seiten Dritter.
          </p>
        </v-col>
      </v-row>
      <v-row >
        <v-col >
          <h3>Angaben zur Datensicherheit und DSGVO</h3>
        </v-col>
      </v-row>
        <v-row class="text-left">
          <v-col>
            <p>Dies Webseite kann u.U. Cookies speichern, die ausschließlich den Nutzer-Comfort optimieren. Es werden keine Tracker verwendet,
              die Nutzeraktivitäten speichern oder an Dritte weiterleiten.
            </p>
            <p>
              Darüber hinaus verpflichten wir uns, keine Ihrer persönlichen Daten an Dritte weiterzuleiten. Wir garantieren dafür, dass keine Ihrer Daten an Dritte für Werbezwecke oder andere Zwecke verkauft oder vermietet werden. Wenn Sie sich für einen Dienst registrieren und dabei Angaben zu Ihrer Person machen, verwenden wir diese Daten nur, soweit es zur Durchführung des Dienstes erforderlich ist.
            </p>
            <p>
              Wir weisen darauf hin, dass über das Internet per E-Mail übermittelte Nachrichten verändert oder verfälscht werden können.
              Herkömmliche E-Mails sind nicht gegen den Zugriff Dritter geschützt und deshalb ist auch die Vertraulichkeit unter Umständen nicht gewahrt.
              Von der Übermittlung sensitiver Geschäftsdaten sollten Sie daher absehen.
            </p>

            <p>
              Mit der ordentlichen Kündigung der Mitgliedschaft werden die persönlichen Angaben des Nutzers automatisch archiviert
              und nach Beendigung der Archivierungsfristen gelöscht.
            </p>
          </v-col>
        </v-row>
        <v-row>
        <v-col>
          <p>
          Außerdem behält sich der Förderkreis Basketball e.V. das Recht vor, Änderungen oder Ergänzungen der bereitgestellten Informationen vorzunehmen.
            Inhalt und Struktur dieser Web-Seite sind urheberrechtlich geschützt.
            Die Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von Texten, Textteilen, Logos oder Bildmaterial bedarf der
            ausdrücklichen vorherigen Zustimmung des Herausgebers.
          </p>
        </v-col></v-row>
    <v-row>
      <v-col>
          <p class="font-weight-bold">
          Grünberg, den 08.04.2023<br>
          Vorstand Förderkreis Basketball e.V.
          </p>
        </v-col>
      </v-row>

  </v-container>
</template>

<script>

export default {
  name: 'ImpressumView',
  data: () => ({
  }),
  props: ["email"]
}
</script>
<style scoped>
.v-row {
  margin-bottom: 10px;
}
p {
  margin-bottom: 10px;
}
</style>