<template>
  <v-container>

      <v-row>
        <v-col   sm="6" cols="12" class="my-auto" >
          <a href="https://tsv-gruenberg.de" >
            <v-img class="logo mx-auto  my-auto" :src="require('@/assets/TSV_Aktionslogo_50Jahre_RGB_gruen.jpg')"></v-img>
          </a>
        </v-col>
        <v-col  sm="6" cols="12" class="my-auto" >
          <a href="https://tsv-gruenberg.de">
            <v-img class="logo mx-auto my-auto" :src="require('@/assets/cropped-BI_Gruenberg-1.png')"></v-img>
          </a>
        </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  name: "FooterComponent"
}
</script>

<style scoped>
.footer {

  background-color: transparent;
  color: white;
}
a {
  color:white;
}
a:visited {
  color: white;
  text-decoration: none!important;
}
.logo {
  max-width:200px;
  max-height: 100px;
}
.logo:hover {
  cursor: pointer;

}
.poweredBy {
  font-weight: normal;
  }
</style>