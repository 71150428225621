<template>

    <v-row class="pb-xl-10 text-left">
      <v-col cols="12" xl="6" xxl="4" md="6" sm="12">
        <h3>Gute Jugendarbeit ist kein Zufall</h3>
        <p>
          Mehrere deutsche Meisterschaften in verschiedenen Altersklassen sind großartige Zeugen einer erfolgreichen Jugendarbeit im Basketball-Leistungssport,
          organisiert und durchgeführt vom <a href="https://tsv-gruenberg.de">TSV 1883 Grünberg Basketball e.V.</a> in Kooperation mit dem
          <a href="https://bi-gruenberg.de">Basketball Internat Grünberg e.V.</a> und <a href="#">Förderkreis Basketball e.V.</a>
        </p>
        <p>
          Um diese Erfolge zu ermöglichen bedarf es großen Anstrengungen, eine Vielzahl von aktiven SpielerInnen, TrainerInnen, Helfern und Unterstützern und zuletzt den notwendigen finanziellen Mitteln.
          </p>
        <p>
          Leistungssport ist aber nur eine Seite der Medaille, denn zur Jugendarbeit gehört auch die Förderung von Kindern und Heranwachsenden, die keinen Leistungsport
          durchführen wollen oder können.
          </p>
      </v-col>
      <v-col cols="12" xl="6" xxl="4" md="6" sm="12">
        <h3>Unsere Rolle</h3>
        <p>
          Der Förderkreis Basketball e.V. unterstützt den TSV 1883 Grünberg e.V. durch finanzielle Zuwendungen und stellt damit zu großen Teilen den Trainings- und Spielbetriebes
          von über 10 Jugendmannschaften sicher, angefangen von den Minis für Kinder unter 9 Jahren bis hin zu den Junioren in der U18.
        </p>
        <p>
          Die finanzielle Unterstützung sorgt u.a. dafür, dass immer ausreichend viele, geeignete Jugendtrainer zur Verfügung stehen und Jugendliche an
          Schulungen und Weiterbildungsmöglichkeiten als Trainer oder Schiedsrichter teilnehmen können.

        </p>
        <p>
          Darüber hinaus unterstützen wir die Teams bei der Beschaffung notwendiger Ausrüstung wie Trikots oder Bälle, Modernisierung von Korbanlagen oder
          bei der Organisation von Turnieren und Camps.
        </p>

      </v-col>
      <v-col cols="12" xl="6" xxl="4" md="6" sm="12">
              <h3>Basketball für Alle</h3>
        <p>
          Eine weitere und besonders wichtige Aufgabe des Förderkreises Basketball e.V. ist es, Kindern aus sozial oder finanziell benachteiligten Familien den Sport im Verein zu ermöglichen.
          <br>
          Denn nicht jede Familie ist in der Lage, die geforderten Mitgliedsbeiträge aufzubringen oder weiteres Geld für Trikots, Sportschuhe oder Fahrten kurzfristig bereitzustellen.
          <br>
          Wir sehen es als unsere Pflicht an, in solchen Fällen Familien zu unterstützen und betroffene Kinder soweit möglich finanziell gleichzustellen.
        </p>
      </v-col>
      <v-col cols="12" xl="6" xxl="4" md="6" sm="12">
        <h3>Basketball für Grünberg</h3>
        <p>
          Unsere Arbeit beschränkt sich nicht auf den Vereinssport, sondern unterstützt auch Maßnahmen und Projekte in der Großgemeinde Grünberg,
          die Kindern und Jugendlichen den Basketball-Sport ermöglichen.

        </p>
        <p>
          Bestes Beispiel hierfür sind öffentliche Korbanlagen in der Großgemeinde oder die Unterstützung von offenen 3x3 Turnieren für Hobby-Teams.
        </p>
      </v-col>
      <v-col cols="12" xl="6" xxl="4" md="6" sm="12">
        <h3>Teamgeist, Toleranz und Fairness - wir vermitteln Werte</h3>
        <p>
          Unsere Kinder und Jugendlichen gewinnen beim Sport nicht nur spielerisch Fähigkeiten im Umgang mit dem runden Ball, sondern erlernen wichtige Werte wie Teamgeist, Toleranz und Fairness.
        </p>
        <p>
          Basketball ist ein toller Mannschaftssport und lehrt den Beteiligten ein gutes und konstruktives Miteinander. Man gewinnt und verliert immer im Team, nie alleine.
        </p>
        <p>
          Im weiteren Verlauf mit Siegen und Niederlagen erlernen die Heranwachsenden darüber hinaus, Verantwortung für ein ganzes Team zu übernehmen und
          entwickeln damit automatisch Durchsetzungsvermögen und Vertrauen in sich selbst und das Team.
        </p>
        <p>
          Wieviel Selbstvertrauen und Stärke hat ein Jugendlicher oder eine Jugendliche aufgebaut , wenn er/sie kurz vor Spielschluss den entscheidenden Freiwurf werfen soll? Würden Sie sich das selbst zutrauen?
        </p>
      </v-col>
      <v-col cols="12" xl="6" xxl="4" md="6" sm="12">
        <h3>Jeder kann Mitglied im Förderkreis Basketball e.V. werden und helfen</h3>
        <p>
          Bitte denken Sie daran! Der Erfolg des Basketballs in Grünberg ist auf finanzielle Unterstütung angewiesen.<br>
          Jugendtrainer, Ausrüstung und Fahrten zu Spielen und Camps benötigen mehr Geld als Sie womöglich vermuten.
        </p>
        <p>
          Als Mitglied im Förderkreis Basketball e.V. helfen Sie durch einen Jahresbeitrag von derzeit 30.- EUR aktiv bei der Jugendarbeit mit.
          Sie müssen hierzu nicht  Mitglied im TSV 1883 Grünberg e.V. sein oder eine aktive Rolle im Basketball begleiten.
          </p>
        <p>Alle Aufgaben im Förderkreis Basketball e.V. werden ehrenamtlich und unentgeltlich geleistet. Wir stellen sicher, dass 100% Ihrer Beiträge bei den
          Kindern und Jugendlichen in Grünberg ankommen.
        </p>
      </v-col>
      <v-col class="text-center">
        <v-btn-group>
          <v-btn  @click="$router.push('mitglied')" color="primary">Mitglied werden</v-btn>
        </v-btn-group>
      </v-col>
    </v-row>
  <v-row class="text-center">
    <v-col cols="12">
      <NavigationComponent></NavigationComponent>
    </v-col>
  </v-row>
</template>

<script>

import NavigationComponent from "@/components/Navigation";
export default {
  name: 'HomepageView',
  components: {NavigationComponent},
  data: () => ({})
}
</script>

<style scoped>
p {
  padding-bottom: 10px;
}


</style>
