
import { createStore } from 'vuex'
import api from "@/store/api";


const initApplication = {
    sepa:{},
    requestorEqualsAccountOwner: true,
}
const store = createStore({
    state () {
        return {
            count: 0,
            application: JSON.parse(JSON.stringify(initApplication)),
            confirmation: null,
        }
    },
    getters: {
      application : (state) => state.application,
        confirmation: (state) => state.confirmation
    },
    mutations: {
        increment (state) {
            state.count++
        },
        setApplication (state,data) {
            state.application = data
        },
        resetApplication (state) {
            state.application = JSON.parse(JSON.stringify(initApplication))
        },
        confirmApplication (state,data) {
            state.confirmation = data
        }
    },
    actions: {
        setApplication(injectee, application) {
          injectee.commit('setApplication',application)
        },
        postApplication(injectee,request) {
            api.postApplication(request).then((resp) => {
                injectee.commit('setApplication', resp.data.body[0])
            })
        },
        resetApplication(injectee) {
                injectee.commit('resetApplication')},
        confirmApplication(injectee,id) {
            api.confirmApplication(id).then((resp) => [
                injectee.commit('confirmApplication',resp.data.body[0])
            ])

        },
        report(injectee,id) {
            api.report(id)
        }
      }
})

export default store