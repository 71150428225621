<template>
  <v-container  class="text-left">

          <div v-if="sent" class="text-center">
            <p v-if="request && request._id && request._id.$oid">
              Vielen Dank für Ihren Mitgliedsantrag, den wir unter der Referenznummer <b>{{ request._id.$oid}}</b> gespeichert haben.
            </p>
            <p>
              Wir haben Ihnen soeben auch eine Bestätigung an die Adresse <span class="email">{{request.email}}</span> gesendet.<br>
              Bitte prüfen Sie die Angaben in der E-Mail und informieren Sie uns bei Problemen.
            </p>
            <p>
             Falls Sie keine E-Mail erhalten haben, prüfen Sie bitte auch Ihren Spam-Ordner.<br>
            </p>
            <div class="text-center" v-if="sent">
            <v-btn-group   >
              <v-btn class="button" @click="sent=false" color="secondary">Zurück zum Antrag</v-btn>
              <v-btn class="button" @click="resend" color="secondary">Nochmal senden</v-btn>
            </v-btn-group>
            </div>
            <div class="py-10" v-if="sent">
              <v-btn-group>
                  <v-btn class="button" @click="reset" color="primary">Antrag abschließen</v-btn>
              </v-btn-group>

          </div>
        </div>

      <v-form v-if="!sent" ref="form" model="valid" >
        <v-card class="my-5">
          <v-card-text>
            <h3>Antragssteller</h3>
          </v-card-text>
        <v-card-text>
            <v-row>
              <TextField cols=12 sm="4" md="2"  label="Anrede" v-model="request.title"></TextField>
              <TextField cols=12 sm="8" md="5" :required="true" label="Vorname" v-model="request.firstName" :rules="[requiredRule('Bitte geben Sie Ihren Vornamen an.')]"></TextField>
              <TextField cols=12 sm="12" md="5" :required="true" label="Nachname" v-model="request.lastName" :rules="[requiredRule('Bitte geben Sie Ihren Nachnamen an.')]"></TextField>
              <TextField cols=12 sm="12" md="5" :required="true" label="Straße" v-model="request.street" :rules="[requiredRule('Bitte geben Sie Ihre Straße an.')]"></TextField>
              <TextField cols=4 sm="4" md="2" :required="true" label="PLZ" v-model="request.zipCode" :rules="[requiredRule('Bitte geben Sie Ihre Postleitzahl an.')]"></TextField>
              <TextField cols=8 sm="8" md="5" :required="true" label="Wohnort" v-model="request.city" :rules="[requiredRule('Bitte geben Sie Ihren Wohnort an.')]"></TextField>

              <TextField @keydown.space="(event) => event.preventDefault()" cols=12 sm="12" md="5" :required="true" label="E-Mail Adresse" :rules="[emailRule('Bitte geben Sie eine gültige E-Mail Addresse an')]" v-model="request.email" ></TextField>
            </v-row>
        </v-card-text>
        </v-card>
        <v-card class="my-5">
          <v-card-text class="pb-10">
            <v-row>
              <v-col cols="12">
                <SepaMandat></SepaMandat>
              </v-col>
            </v-row>
            <v-row>
              <TextField @keydown.space="(event) => event.preventDefault()" @keyup="onKeyup" cols=12 xs="12" sm="12" md="6"  label="IBAN" v-model="sepa.iban" :required="true" :rules="[ibanRule('Bitte geben Sie eine gültige IBAN ein.')]">
              </TextField>
              <TextField  cols=12 xs="12" sm="12" md="6"  label="Kreditinstitut (Name)" v-model="sepa.bank" :required="true" :rules="[requiredRule('Bitte geben Sie einen Banknamen ein.')]"></TextField>
            </v-row>
            <v-row class="px-10">
              <checkbox-field label="Antragsteller ist auch Kontoinhaber" v-model="request.requestorEqualsAccountOwner"></checkbox-field>
            </v-row>
            <v-row  v-if="request.requestorEqualsAccountOwner===false">
              <TextField cols=12 sm="4" md="2"  label="Anrede" v-model="sepa.title"></TextField>
              <TextField cols=12 sm="8" md="5" :required="true" label="Vorname" v-model="sepa.firstName"  :rules="[requiredRule('Bitte geben Sie Ihren Vornamen an.')]"></TextField>
              <TextField cols=12 sm="12" md="5" :required="true" label="Nachname" v-model="sepa.lastName"  :rules="[requiredRule('Bitte geben Sie Ihren Nachnamen an.')]"></TextField>
              <TextField cols=12 sm="12" md="5" :required="true" label="Straße" v-model="sepa.street"  :rules="[requiredRule('Bitte geben Sie Ihre Straße an.')]"></TextField>
              <TextField cols=4 sm="4" md="2" :required="true" label="PLZ" v-model="sepa.zip"  :rules="[requiredRule('Bitte geben Sie Ihre Postleitzahl an.')]"></TextField>
              <TextField cols=8 sm="8" md="5" :required="true" label="Wohnort" v-model="sepa.city"  :rules="[requiredRule('Bitte geben Sie Ihren Wohnort an.')]"></TextField>
            </v-row>

          </v-card-text>
        <v-row>
        </v-row>
        </v-card>
        <v-card>
          <v-card-text>
            <v-row>
            <TermsConditions class="px-5" :email="email"></TermsConditions>
              <div class="ml-10">
              <checkbox-field required :rules="[requiredCheckbox('Bitte akzeptieren Sie die Bedingungen')]" label="Ich/wir akzeptiere(n) die Mitgliedschaftsbedingungen." v-model="request.acceptTerms"></checkbox-field>
              <CheckboxField  required :rules="[requiredCheckbox('Bitte akzeptieren Sie dieses Feld')]"  v-model="request.acceptPayments" label="Ich/wir akzeptiere(n), dass der Beitrag erstmalig vier Wochen nach Abschluss und in den Folgejahren jeweils im November eingezogen wird."></CheckboxField>
              <CheckboxField  v-model="request.acceptInfo" label="Ich/wir möchte(n) per E-Mail über Neuigkeiten des Förderkreises Basketball e.V. informiert werden."></CheckboxField>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
<v-row>
  <v-col class="hint">
    Mit dem * gekennzeichnete Felder sind verpflichtend.
  </v-col>
</v-row>
        <v-row dense class="ma-10 text-center">
          <v-col cols="12">

            <v-btn-group>
              <v-btn v-if="!valid" @click="validate" color="primary">Antrag prüfen</v-btn>

                <v-btn  v-if="valid" @click="send" color="green">Antrag senden</v-btn>

            </v-btn-group>
          </v-col>
          <v-col v-if="valid">
          <p >
            Sie haben alle notwendigen Angaben gemacht, so dass Ihr Mitgliedsantrag nun zu uns versendet werden kann.
          </p>

          </v-col>
        </v-row>
      </v-form>
  </v-container>
</template>

<script>


import TextField from "@/components/TextField";
import FormValidationMixin from "@/mixins/FormValidationMixin";
import CheckboxField from "@/components/CheckboxField";
import SepaMandat from "@/components/SepaMandat";
import TermsConditions from "@/components/Terms";
import {mapGetters, mapActions} from 'vuex'
import iban from "@/mixins/iban";
import {debounce} from "@/mixins/debounce";

export default {
  name: "AntragView",
  components: {TermsConditions, SepaMandat, CheckboxField, TextField},
  mixins: [FormValidationMixin],
  props: {email:{default:"info@foerderkreis-basketball.de"}},
  computed: {
    ...mapGetters(['application']),
    request: {
      get: function() { return this.application},
      set: function(val) { this.setApplication(val) }
    },
    sepa: {
      get : function() { return this.request.sepa},
      set: function(val) { this.request.sepa = val }
    }
  },
  data: () => ({
    valid: false,
    sent: false,
    debounceFn : null,
  }),
  methods: {
    ...mapActions(['setApplication']),
    validate() {
      this.$refs.form.validate().then( (resp) => {
        this.valid = resp.valid
      })
    },
    send() {
      this.$refs.form.validate().then( (resp) => {
        if (resp.valid) {
          this.postApplication(this.request)
          this.sent = true
        }
      })
    },
    resend() {
      this.postApplication(this.request)

    },
    reset() {
      this.resetApplication()
      this.$router.push('/')
    },
    onKeyup() {
        this.debounceFn()
    },
    async getBankName() {

      if (iban.Validate(this.request.sepa.iban) && !this.request.sepa.bank) {
        iban.GetBankName(this.request.sepa.iban).then((resp) => {
          if (resp.valid && resp.bankdata) this.request.sepa.bank = resp.bankdata.name
        })

      }
    },
    ...mapActions(["postApplication","resetApplication"])
  },
  watch: {
    request: {
      deep: true,
      handler() {

      }
    }
  },
  mounted() {
    this.debounceFn = debounce(() => this.getBankName(), 400)
  }
}
</script>

<style scoped>
  p {
    padding-bottom:10px;
    padding-left: 25px;
  }
  .email {
    color: #9C2C13;
  }
  .button {
    margin-right:20px;

  }
  .hint {
    font-size: small;
  }
</style>