<template>
  <div class="text-center pb-10">
    <img @click="$router.push('/')"
           :src="require('@/assets/logo.png')"
           class=" logo"
    />
  </div>
  <v-app name="app">
      <v-container >
        <v-row>
        <v-col>
          <v-main>
            <router-view></router-view>
          </v-main>
        </v-col>
      </v-row>

   <FooterComponent v-if="false"></FooterComponent>
    <v-footer absolute app>
      <v-container>
        <v-row class="text-center">
          <v-col cols="12" class="copyright">
            &copy;2023 - Förderkreis Basketball e.V.
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
      </v-container>

  </v-app>
</template>

<script>
import FooterComponent from "@/components/Footer";

export default {
  name: 'App',
  components: {FooterComponent},
  data: () => ({
  }),

}
</script>
<style >

.logo {
  width:90%;
  max-width:600px;
}
a:visited {

  text-decoration: none!important;
}
a {
  font-weight: bolder;
  color: black;
  text-decoration-line: none;
}
.logo:hover {
  cursor: pointer;
}
.copyright {
  font-size: smaller;
  font-weight: bold;
}
</style>