<template>
  <v-container>
    <v-row >
      <v-col >
        <h3>Angaben zur Datensicherheit und DSGVO</h3>
      </v-col>
      <v-row class="text-left">
        <v-col>
          <p>Dies Webseite kann u.U. Cookies speichern, die ausschließlich den Nutzer-Comfort optimieren. Es werden keine Tracker verwendet,
          die Nutzeraktivitäten speichern oder an Dritte weiterleiten.
          </p>
          <p>
            Darüber hinaus verpflichten wir uns, keine Ihrer persönlichen Daten an Dritte weiterzuleiten. Wir garantieren dafür, dass keine Ihrer Daten an Dritte für Werbezwecke oder andere Zwecke verkauft oder vermietet werden. Wenn Sie sich für einen Dienst registrieren und dabei Angaben zu Ihrer Person machen, verwenden wir diese Daten nur, soweit es zur Durchführung des Dienstes erforderlich ist.
          </p>
          <p>
            Wir weisen darauf hin, dass über das Internet per E-Mail übermittelte Nachrichten verändert oder verfälscht werden können.
            Herkömmliche E-Mails sind nicht gegen den Zugriff Dritter geschützt und deshalb ist auch die Vertraulichkeit unter Umständen nicht gewahrt.
            Von der Übermittlung sensitiver Geschäftsdaten sollten Sie daher absehen.
          </p>

          <p>
            Mit der ordentlichen Kündigung der Mitgliedschaft werden die persönlichen Angaben des Nutzers automatisch archiviert
            und nach Beendigung der Archivierungsfristen gelöscht.
          </p>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'DatensicherheitView',
  data: () => ({
  }),
}
</script>

<style scoped>
p {
  padding-left:10px;
  padding-bottom: 10px;
}
</style>