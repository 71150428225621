<template>
  <v-col :cols="cols">
    <v-text-field single-line :rules="rules"  clearable density="compact" :label="getLabel" v-model="data"></v-text-field>
  </v-col>
</template>

<script>
export default {
  name: "TextField",
  props: {
    label:{},
    modelValue:{},
    rules:{},
    cols:{},
    required:{
      type: Boolean,
      default: false,
    }
  },
  computed: {
    getLabel: {
      get: function() { return this.required?this.label+"*":this.label},
    },
    data: {
      get: function() { return this.modelValue},
      set: function(val) { this.$emit('update:modelValue',val)}
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>