<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h1>Bestätigung Ihrer Mitgliedschaft</h1>
      </v-col>
        <v-col cols="12">
          <h3 v-if="confirmation && confirmation.text">
            {{ confirmation.text }}
          </h3>
        </v-col>
      <v-col class="text-center" cols="12">
        <v-btn-group>
          <v-btn  @click="$router.push('/')" color="primary">Sie können die Seite jetzt schließen.</v-btn>
        </v-btn-group>
      </v-col>
      </v-row>

  </v-container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ConfirmationView',
  computed: {
    ...mapGetters(['confirmation'])
  },
  data: () => ({
  }),
  methods: {
    ...mapActions(['confirmApplication'])
  },
  mounted() {
    const id = this.$route.query.id
    if (id) {
      this.confirmApplication((id))
    }
  }
}
</script>

<style scoped>
p {
  padding-left:10px;
  padding-bottom: 10px;
}
</style>