<template>
  <div>
    <h3>SEPA-Lastschriftmandat</h3>
    <p>
      Ich ermächtige den Förderkreis Basketball e.V. Zahlungen von meinem Konto mittels SEPA-Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an,
      die vom Förderkreis Basketball e.V. auf mein Konto gezogenen Lastschriften einzulösen.
    </p>
    <p>
      <b>Hinweis</b>: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattungen des belasteteten Betrages verlangen.
      Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen und Gebühren.
    </p>
  </div>
</template>

<script>
export default {
  name: "SepaMandat"
}
</script>

<style scoped>
p {
  font-size: medium;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left:10px;
}
</style>