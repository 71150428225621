<template>
      <v-btn
          elevation="0"

          class="button"
          @click="$router.push('/impressum')"
      >
        Impressum
      </v-btn>
</template>

<script>
export default {
  name: "NavigationComponent"
}
</script>

<style scoped>

</style>